<template>
  <b-container>
    <ct-centered-spinner v-if="loading" />
    <div v-else class="companies-monitoring-page">
      <monitoring-instructional-accordion
        :id="'company-collapse'"
        :header="'Company Monitoring'"
        :instructional-text="instructionalContent"
      />
      <monitoring-identity-table 
        :table-data="companies" 
        identity-type-string-singular="Company" 
        identity-type-string-plural="Companies" 
        :item-type="identityType" 
        :owned-data="formatCompanyData"
      />
    </div>
  </b-container>
</template>

<script>
import MonitoringInstructionalAccordion from '@/components/Monitoring/MonitoringInstructionalAccordion.vue'
import MonitoringIdentityTable from "@/components/Monitoring/MonitoringIdentityTable.vue"
import { mapGetters, mapActions } from 'vuex'
import { MonitoringIdentityType } from '@/common/modules/constants'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: "MonitoringCompaniesPage",
  components: {
    MonitoringIdentityTable,
    CtCenteredSpinner,
    MonitoringInstructionalAccordion,
  },
  data() {
    return {
      companies: [],
      identityType: MonitoringIdentityType.COMPANY,
      loading: false,
      instructionalContent: {
        sections: [
          {
            header: 'Set up a Company Identity to monitor',
            generic: 'You have two options for adding a company to monitor:',
            items: [
              "<strong>Select from registered companies:</strong> Click “Add Company from your account” below to see a list of companies you've registered with our organization. Selecting the preferred company name from the list ensures you're monitoring companies on record with us.",
              "<strong>Add a new company:</strong> Click “Add external Company” below and enter the name of the company you want to monitor. This is useful if you have a specific company in mind that you haven't registered with us yet.",
            ],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    ...mapGetters('monitoring', [
      'monitoringIdentitiesByType',
    ]),
    ...mapGetters('companies', {
      defaultCompanies: 'companies',
    }),
    formatCompanyData() {
      return this.defaultCompanies.map(company => ({ value: company.name, text: company.name }))
    },
  },
  async mounted() {
    this.loading = true
    await this.loadMore(this)
    await this.fetchMonitoringIdentitiesByType({ accountID: this.actualAccountID, identityType: MonitoringIdentityType.COMPANY })
    this.companies = this.monitoringIdentitiesByType[MonitoringIdentityType.COMPANY]
    this.loading = false
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringIdentitiesByType',
    ]),
    ...mapActions('companies', [
      'loadMore',
    ]),
  },
}
</script>
